@font-face {
  font-family: "Kanit-Regular";
  src: url("../fonts/Kanit-Regular.woff2") format("woff2"),
    url("../fonts/Kanit-Regular.ttf") format("truetype"),
    url("../fonts/Kanit-Regular.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Kanit-Bold";
  src: url("../fonts/Kanit-Bold.woff2") format("woff2"),
    url("../fonts/Kanit-Bold.ttf") format("truetype"),
    url("../fonts/Kanit-Bold.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Kanit-Medium";
  src: url("../fonts/Kanit-Medium.woff2") format("woff2"),
    url("../fonts/Kanit-Medium.ttf") format("truetype"),
    url("../fonts/Kanit-Medium.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Kanit-Light";
  src: url("../fonts/Kanit-Light.woff2") format("woff2"),
    url("../fonts/Kanit-Light.ttf") format("truetype"),
    url("../fonts/Kanit-Light.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Kanit-ExtraLight";
  src: url("../fonts/Kanit-Extra-Light.ttf") format("truetype"),
    url("../fonts/Kanit-Extra-Light.woff2") format("woff2"),
    url("../fonts/Kanit-Extra-Light.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Kanit-Thin";
  src: url("../fonts/Kanit-Thin.woff2") format("woff2"),
    url("../fonts/Kanit-Thin.ttf") format("truetype"),
    url("../fonts/Kanit-Thin.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}
